// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-sidenav-container {
  min-height: 100vh;
  overflow: hidden;
}

mat-sidenav {
  min-width: 270px;
  width: auto;
  min-height: 100%;
  height: 100%;
  padding: 5px;
  background-color: #011d44;
}

mat-sidenav-content {
  width: auto;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  mat-sidenav {
    min-width: 170px;
    width: 170px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./src/utilities/variables.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,yBCbgB;ADWlB;;AAMA;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAHF;;AAMA;EACE,uBAAA;EACA;IACE,gBAAA;IACA,YAAA;EAHF;AACF","sourcesContent":["// Utilities\r\n@import \"/src/utilities/variables\";\r\n\r\nmat-sidenav-container {\r\n  min-height: 100vh;\r\n  overflow: hidden;\r\n}\r\n\r\nmat-sidenav {\r\n  min-width: 270px;\r\n  width: auto;\r\n  min-height: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  background-color: $color_dark_blue;\r\n\r\n}\r\n\r\nmat-sidenav-content {\r\n  width: auto;\r\n  height: 100%;\r\n  overflow: auto;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  /* For mobile phones: */\r\n  mat-sidenav {\r\n    min-width: 170px;\r\n    width: 170px;\r\n  }\r\n}\r\n","$color_black: #000215;\r\n$color_dark_blue: #011d44;\r\n$color_light_blue: #81c0e4;\r\n$color_purple: #c4c5ea;\r\n$color_white: #fefcf9;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
