import { Component, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth-service";
import { User } from "src/app/models/user.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isAuth: Observable<boolean>;
  @Output() menuButton = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {
    // console.log('Genearate Good Header');
    this.isAuth = this.authService.isAuthenticated();
  }

  ngOnInit() {}
}
