// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none;
  color: #fefcf9 !important;
  font-size: 1rem;
}
a:hover {
  color: #81c0e4;
}
a:active {
  color: #81c0e4;
}

.nav-caption {
  color: #fefcf9;
}
.nav-caption:hover {
  color: #81c0e4;
}
.nav-caption:active {
  color: #81c0e4;
}

.datePicker {
  background-color: #011d44;
  color: #fefcf9;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border: 2px solid #000215;
}
.datePicker input {
  color: #81c0e4;
  text-align: center;
}
.datePicker ::placeholder,
.datePicker mat-datepicker-toggle {
  color: #81c0e4;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.sidenav {
  background-color: #011d44;
  color: #fefcf9;
}

h4 {
  font-size: 1em;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  a {
    height: 30px !important;
  }
  a .nav-caption {
    font-size: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/sidenav-list/sidenav-list.component.scss","webpack://./src/utilities/variables.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,yBAAA;EAOA,eAAA;AAPF;AACE;EACE,cCJe;ADKnB;AACE;EACE,cCPe;ADQnB;;AAIA;EACE,cCXY;ADUd;AAEE;EACE,cCfe;ADenB;AAEE;EACE,cClBe;ADkBnB;;AAIA;EACE,yBCxBgB;EDyBhB,cCtBY;EDuBZ,wBAAA;EAAA,mBAAA;EACA,WAAA;EACA,yBAAA;AADF;AAEE;EACE,cC7Be;ED8Bf,kBAAA;AAAJ;AAEE;;EAEE,cClCe;ADkCnB;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,yBC5CgB;ED6ChB,cC1CY;ADyCd;;AAIA;EACE,cAAA;EACA,kBAAA;AADF;;AAIA;EACE,uBAAA;EACA;IACE,uBAAA;EADF;EAEE;IACE,eAAA;EAAJ;AACF","sourcesContent":["@import \"/src/utilities/variables\";\r\n\r\na {\r\n  text-decoration: none;\r\n  color: $color_white!important;\r\n  &:hover {\r\n    color: $color_light_blue;\r\n  }\r\n  &:active {\r\n    color: $color_light_blue;\r\n  }\r\n  font-size: 1rem;\r\n}\r\n\r\n.nav-caption {\r\n  color: $color_white;\r\n  &:hover {\r\n    color: $color_light_blue;\r\n  }\r\n  &:active {\r\n    color: $color_light_blue;\r\n  }\r\n}\r\n\r\n.datePicker {\r\n  background-color: $color_dark_blue;\r\n  color: $color_white;\r\n  height: fit-content;\r\n  width: 100%;\r\n  border: 2px solid $color_black;\r\n  input {\r\n    color: $color_light_blue;\r\n    text-align: center;\r\n  }\r\n  ::placeholder,\r\n  mat-datepicker-toggle {\r\n    color: $color_light_blue;\r\n  }\r\n}\r\n\r\n.mat-mdc-form-field-subscript-wrapper {\r\n  display: none!important;\r\n}\r\n\r\n.sidenav {\r\n  background-color: $color_dark_blue;\r\n  color: $color_white;\r\n}\r\n\r\nh4 {\r\n  font-size: 1em;\r\n  text-align: center;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  /* For mobile phones: */\r\n  a {\r\n    height: 30px!important;\r\n    .nav-caption {\r\n      font-size: 10px;\r\n    }\r\n  }\r\n}","$color_black: #000215;\r\n$color_dark_blue: #011d44;\r\n$color_light_blue: #81c0e4;\r\n$color_purple: #c4c5ea;\r\n$color_white: #fefcf9;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
