import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './layouts/header/header.component';
import { SidenavListComponent } from './layouts/sidenav-list/sidenav-list.component';
import { AccueilComponent } from './pages/accueil/accueil.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthService } from './services/auth/auth-service';
import { SignupComponent } from './auth/signup/signup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { LoggedHeaderComponent } from './layouts/header/logged-header/logged-header.component';
import { UnloggedHeaderComponent } from './layouts/header/unlogged-header/unlogged-header.component';
import { SignoutComponent } from './auth/signout/signout.component';
import { GeneralComponent } from './components/general/general.component';
import { StatsService } from './services/stats/stats-service';
import { MathsService } from './services/maths/maths-service';
import { TrelloService } from './services/trello/trello.service';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import { RosterComponent } from './roster/roster.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule} from '@angular/material/tabs';
import { RosterPointsComponent } from './components/roster-points/roster-points.component';
import { MainRosterComponent } from './components/main-roster/main-roster.component';
import { StatsAnalyserComponent } from './components/stats-analyser/stats-analyser.component';
import { LastGamesComponent } from './components/last-games/last-games.component';

@NgModule({
  declarations: [
    LastGamesComponent,
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    AccueilComponent,
    SigninComponent,
    SignupComponent,
    LoggedHeaderComponent,
    UnloggedHeaderComponent,
    SignoutComponent,
    GeneralComponent,
    RosterComponent,
    RosterPointsComponent,
    MainRosterComponent,
    StatsAnalyserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    FormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    BrowserAnimationsModule ,
    MatTabsModule

  ],
  exports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    BrowserAnimationsModule ,
    MatTabsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    StatsService,
    MathsService,
    TrelloService,
    DatePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
