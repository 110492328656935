import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Game } from "src/app/models/games/games.models";
import { MathsService } from "src/app/services/maths/maths-service";
import { StatsService } from "src/app/services/stats/stats-service";

@Component({
  selector: "app-sidenav-list",
  templateUrl: "./sidenav-list.component.html",
  styleUrls: ["./sidenav-list.component.scss"],
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  actualGameList: Game[];
  teamList: string[];
  percentResult: number;
  gamesDate = this.datePipe.transform(
    new Date().setDate(new Date().getDate()),
    "YYYY/MM/dd"
  );
  gamesDateToShow = this.datePipe.transform(
    new Date().setDate(new Date().getDate()),
    "dd-MM-YYYY"
  );
  lastUpdate = "07/03/2024 ";

  constructor(
    private statsService: StatsService,
    private mathsService: MathsService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.getSchedule();

    this.statsService.getCurrentGameList().subscribe((gameList) => {
      this.actualGameList = gameList;
      // console.log("actualGameList", this.actualGameList);
    });
  }

  ngOnInit() {}

  addEvent(date: Date) {
    // console.log("date");
    this.gamesDate = this.datePipe.transform(date, "YYYY/MM/dd");
    this.getSchedule();
    // this.redirectTo('/game/view/0');
  }

  getSchedule() {
    return this.statsService
    .getGameList(this.gamesDate)
    .subscribe((apiResponse) => {
        let goodBetCount = 0;
        // console.log("getSchedule apiResponse", apiResponse);
        if (!apiResponse.length) {
          alert("Pas de match a cette date");
          return
        }
        this.statsService.game.next(apiResponse);

        apiResponse.forEach(async (game) => {
          
          await this.statsService
            .getTeamStats((
              this.statsService.getCorrectName(game.roadTeam)), this.gamesDate
              )
            .subscribe(async (gameStats) => {
              let statsRoad = gameStats;
              await this.statsService
                .getTeamStats(
                  this.statsService.getCorrectName(game.homeTeam), this.gamesDate)
                .subscribe(async (gameStats) => {
                  let statsHome = gameStats;
                  let pronoWinner = this.mathsService.getWinner(
                    this.mathsService.setAverage(
                      statsRoad?.roadTraditional[0].points,
                      statsHome?.homeOpponent[0].points,
                      
                    ),
                    this.mathsService.setAverage(
                      statsHome?.homeTraditional[0].points,
                      statsRoad?.roadOpponent[0].points
                    ),
                    game.homeTeam,
                    game.roadTeam
                  );
                  
                  // console.log("REAL WINNER ==> ",game.winner);
                  // console.log("PRONO ==> ",pronoWinner);
                  if (game.winner == pronoWinner) {
                    // console.log("win");

                    goodBetCount += 1;
                    // console.log("sum => ", goodBetCount);
                  }
                  // console.log("percentResult",goodBetCount,this.actualGameList.length);
                  this.percentResult =
                    goodBetCount / this.actualGameList.length;
                });
            });
        });
        localStorage.setItem("gameList", JSON.stringify(apiResponse));
      });
  }

  onSidenavClose() {
    this.sidenavClose.emit();
  }

  redirectTo(uri) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  onViewGame(id: number) {
    this.redirectTo("/game/view/" + id);
  }

}
