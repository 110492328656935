import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, lastValueFrom } from "rxjs";
import {
  Game,
  HomeRanks,
  RoadRanks,
  ScoringPosition,
  StandingsOldModel,
  TeamStats,
} from "src/app/models/games/games.models";
import { MathsService } from "src/app/services/maths/maths-service";
import { StatsService } from "src/app/services/stats/stats-service";

@Component({
  selector: "app-stats-analyser",
  templateUrl: "./stats-analyser.component.html",
  styleUrls: ["./stats-analyser.component.scss"],
})
export class StatsAnalyserComponent implements OnInit {
  @Input() game: Game;
  roadTeamStats: TeamStats;
  homeTeamStats: TeamStats;
  homeScoringPosition: ScoringPosition;
  roadScoringPosition: ScoringPosition;
  @Input() homeStanding: StandingsOldModel[] = [];
  @Input() roadStanding: StandingsOldModel[] = [];
  @Input() homeRanks: HomeRanks;
  @Input() roadRanks: RoadRanks;
  @Input() date: string;

  constructor(
    private statsService: StatsService,
    private mathsService: MathsService
  ) {}

  async ngOnInit(): Promise<void> {
    const [roadTeamStats, homeTeamStats] = await lastValueFrom(
      forkJoin([
        this.statsService.getTeamStats(
          this.statsService.getCorrectName(this.game.roadTeam), this.date
        ),
        this.statsService.getTeamStats(
          this.statsService.getCorrectName(this.game.homeTeam), this.date
        ),
      ])
    );

    const [roadScoringPosition, homeScoringPosition] = await lastValueFrom(
      forkJoin([
        this.statsService.getScoringPosition(
          this.statsService.getCorrectName(this.game.roadTeam)
        ),
        this.statsService.getScoringPosition(
          this.statsService.getCorrectName(this.game.homeTeam)
        ),
      ])
    );


    this.homeScoringPosition = homeScoringPosition
    this.roadScoringPosition = roadScoringPosition

    this.roadTeamStats = roadTeamStats;
    this.homeTeamStats = homeTeamStats;

    this.statsService.roadStatsTrigger.subscribe((points) => {
      this.roadTeamStats.roadTraditional[0].points += points;
      document.getElementById("road-ppg").textContent =
        this.roadTeamStats.roadTraditional[0].points.toString();
    });

    this.statsService.homeStatsTrigger.subscribe((points) => {
      this.homeTeamStats.homeTraditional[0].points += points;
      document.getElementById("home-ppg").innerHTML =
        this.homeTeamStats.homeTraditional[0].points.toString();
    });
  }

  biggerRank(roadValue: number, homeValue: number) {
    if (homeValue > roadValue) {
      return this.game.homeName;
    } else {
      return this.game.roadName;
    }
  }

  biggerRankInverted(roadValue: number, homeValue: number) {
    if (homeValue > roadValue) {
      return this.game.roadName;
    } else {
      return this.game.homeName;
    }
  }

  setAverage(v1: number, v2: number) {
    return this.mathsService.setAverage(v1, v2);
  }

  getWinner(v1: number, v2: number, home: string, road: string, printLocation = false) {
    return this.mathsService.getWinner(v1, v2, home, road, printLocation);
  }

  isOvertime(v1: number, v2: number) {
    if (Math.abs(v1 - v2) < 2) {
      return "Overtime";
    } else if (Math.abs(v1 - v2) > 4) {
      return "EasyWin?";
    } else {
      return "";
    }
  }

  isGoodProno(
    roadScore: number,
    homeScore: number,
    roadTeam: string,
    homeTeam: string,
    pronoWinner: string
  ) {
    let realWinner = +roadScore > +homeScore ? roadTeam : homeTeam;
    if (!roadScore) {
      return "white";
    }
    if (pronoWinner == realWinner) {
      return "green";
    } else {
      return "red";
    }
  }
}
