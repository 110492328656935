import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { TeamRoster } from '../models/games/games.models';
import { StatsService } from '../services/stats/stats-service';
import { MatSort } from '@angular/material/sort';
import {
  MatCheckboxChange,
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class RosterComponent implements OnInit, AfterViewInit {
  @Input() teamRosterHome: string;
  @Input() teamRosterRoad: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'select',
    'player',
    'gamePlayed',
    'wins',
    'loses',
    'usageAverage',
    'fieldGoalsMade',
    'threePointsMade',
    'freeThrowsMade',
    'rebounds',
    'assists',
    'points',
  ];
  dataSourceHome = new MatTableDataSource<TeamRoster>();
  dataSourceRoad = new MatTableDataSource<TeamRoster>();
  selection = new SelectionModel<TeamRoster>(true, []);

  constructor(private statsService: StatsService) {}

  ngOnInit(): void {
    console.log('Rosters Update');
    // this.statsService
    //   .getRosterList(this.getCorrectName(this.teamRosterHome))
    //   .subscribe((rosterList) => {
    //     this.dataSourceHome.data = rosterList.rosterList;
    //     this.dataSourceHome.data.forEach((row) => {
    //       if (localStorage.getItem(row.player)) {
    //         this.selection.select(row);
    //       }
    //     });
    //   });

    // this.statsService
    //   .getRosterList(this.getCorrectName(this.teamRosterRoad))
    //   .subscribe((rosterList) => {
    //     this.dataSourceRoad.data = rosterList.rosterList;
    //     this.dataSourceRoad.data.forEach((row) => {
    //       if (localStorage.getItem(row.player)) {
    //         this.selection.select(row);
    //       }
    //     });
    //   });
  }

  ngAfterViewInit() {
    this.dataSourceHome.sort = this.sort;
    this.dataSourceRoad.sort = this.sort;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceHome.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceHome.data.forEach((row: TeamRoster) =>
          this.selection.select(row)
        );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TeamRoster): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.player
    }`;
  }

  getCorrectName(v1: string) {
    if (v1 === 'Los Angeles Clippers') {
      return 'LA Clippers';
    } else {
      return v1;
    }
  }

  newToggleRow(row: TeamRoster, event: MatCheckboxChange) {
    if (event.source.checked) {
      localStorage.setItem(row.player, 'true');
    } else {
      localStorage.removeItem(row.player);
    }
  }
}
