import { OnInit } from '@angular/core';

import { Injectable } from '@angular/core';

@Injectable()
export class MathsService {

  getAverage(i: string, y: string) {
    const first = +i;
    const second = +y;
    return ((first + second) / 2);
  }

  setAverage(v1: number, v2: number) {
    return Number((Math.abs(v1 + v2) / 2).toFixed(1));
  }

  getWinner(v1: number, v2: number, home: string, road: string, printLocation = false) {
    if (v1 > v2) {
      if (printLocation) {
        return road + ' (EXT.)';
      } else {
        return road;
      }
    } else {
      if (printLocation) {
        return home + ' (DOM.) ';
      } else {
        return home;
      }
    }
  }
  setAverageNew(off: number, def: number, defSecond: number) {
    return Number((Math.abs(off * def) / defSecond).toFixed(1));
  }
}
