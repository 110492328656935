import { Component, OnInit, Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  FormArray,
  FormControl
} from '@angular/forms';
import { AuthService } from '../../services/auth/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../models/user.model';
import { ApiResponse } from '../../services/auth/signup-response';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
@Injectable()
export class SignupComponent implements OnInit {
  signupForm: UntypedFormGroup;
  errorMessage: string;

  roles = ['ADMIN', 'USER'];

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.signupForm = this.formBuilder.group({
      lastname: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]
      ],
      gender: ['', [Validators.required]]
    });
  }

  onSubmit() {
    const lastname = this.signupForm.get('lastname').value;
    const firstname = this.signupForm.get('firstname').value;
    const birthdate = this.signupForm.get('birthdate').value;
    const email = this.signupForm.get('email').value;
    const password = this.signupForm.get('password').value;
    const gender = this.signupForm.get('gender').value;

    const user = new User(
      lastname,
      firstname,
      birthdate,
      email,
      gender,
      password
    );

    this.authService.createNewUser(user).subscribe(
      (signupResponse: ApiResponse) => {
        if (signupResponse.success) {
          this.router.navigate(['/home']);
          console.log(signupResponse);
          window.alert(signupResponse.message);
        } else {
          window.alert(signupResponse.message);
        }
      },
      error => (this.errorMessage = error)
    );
  }
}
