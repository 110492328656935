// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rosters {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.rosterHome, .rosterRoad {
  margin-top: 50px;
  display: flex;
  justify-self: center;
  width: 90%;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .rosterHome, .rosterRoad {
    font-size: 10px;
    width: 90%;
  }
  .rosterHome th, .rosterHome td, .rosterRoad th, .rosterRoad td {
    font-size: 9px;
    white-space: nowrap;
    height: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/roster-points/roster-points.component.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,8BAAA;AAHF;;AAMA;EACE,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,UAAA;AAHF;;AAMA;EAEE,uBAAA;EACA;IACE,eAAA;IACA,UAAA;EAJF;EAKE;IACE,cAAA;IACA,mBAAA;IACA,YAAA;EAHJ;AACF","sourcesContent":["// table {\r\n//   width: 50%;\r\n// }\r\n\r\n.rosters {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n}\r\n\r\n.rosterHome, .rosterRoad {\r\n  margin-top: 50px;\r\n  display: flex;\r\n  justify-self: center;\r\n  width: 90%;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n\r\n  /* For mobile phones: */\r\n  .rosterHome, .rosterRoad {\r\n    font-size: 10px;\r\n    width: 90%;\r\n    th, td {\r\n      font-size: 9px;\r\n      white-space: nowrap;\r\n      height: 10px;\r\n    }\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
