import { Component, OnInit } from "@angular/core";
import { StatsService } from "src/app/services/stats/stats-service";
import { ActivatedRoute } from "@angular/router";
import {
  Game,
  TeamStats,
  StandingsOldModel,
  HomeRanks,
  RoadRanks,
  RosterList,
  Standings,
  LastFive,
  Rest,
  Streak,
} from "src/app/models/games/games.models";
import { MathsService } from "src/app/services/maths/maths-service";
import { TrelloService } from "src/app/services/trello/trello.service";
import { DatePipe } from "@angular/common";
import { AverageStat, GameStats } from "src/app/models/games/team-stats.model";
import { forkJoin, lastValueFrom } from "rxjs";

@Component({
  selector: "app-accueil",
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"],
})
export class AccueilComponent implements OnInit {
  date = this.datePipe.transform(
    new Date().setDate(new Date().getDate() - 1),
    "yyyy/MM/dd"
  );
  game: Game;
  last = "13";
  gameDay: string;
  homeTeamStats: AverageStat = null;
  roadTeamStats: AverageStat = null;
  homeStanding: Standings = null;
  roadStanding: Standings = null;
  road = "road";
  home = "home";

  homeSimulationPoints: string = null;
  roadSimulationPoints: string = null;
  roadInfos: string[];
  homeInfos: string[];
  homeRanks: HomeRanks;
  roadRanks: RoadRanks;
  rosterListHome: RosterList;
  homeRest: Rest;
  roadRest: Rest;
  homeStreak : string
  roadStreak : string
  homeBTB: string;
  roadBTB: string;
  homeScoringPosition: any;
  roadScoringPosition: any;

  constructor(
    private statsService: StatsService,
    private mathService: MathsService,
    private route: ActivatedRoute,
    private trelloService: TrelloService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.statsService.getCurrentGameList().subscribe(async (gameList) => {
      this.game = gameList[id];
      this.gameDay = this.datePipe.transform(this.game.date, "yyyy/MM/dd");
      // console.log(this.game);

      this.statsService
        .getStandings(this.game.homeTeam)
        .subscribe((standingsHome) => {
          // console.log("standings Home -- ", standingsHome);
          this.homeStanding = standingsHome;
        });
      this.statsService
        .getStandings(this.game.roadTeam)
        .subscribe((standingsRoad) => {
          // console.log("standings Road -- ", standingsRoad);
          this.roadStanding = standingsRoad;
        });

      this.statsService
        .getRest(this.game.roadTeam, this.gameDay)
        .subscribe((rest) => {
          // console.log("rest Road -- ", rest);
          this.roadRest = rest;
          this.roadBTB = rest.isBackToBack ? "| B2B" : "| +1";
        });

      this.statsService
        .getRest(this.game.homeTeam, this.gameDay)
        .subscribe((rest) => {
          // console.log("rest Home -- ", rest);
          this.homeRest = rest;
          this.homeBTB = rest.isBackToBack ? "| B2B" : "| +1";
        });
        
        this.statsService
        .getStreak(this.game.homeTeam, this.gameDay)
        .subscribe((streak) => {
          this.homeStreak = streak.win? "W"+streak.streak :"L"+streak.streak;
        });
        
        this.statsService
        .getStreak(this.game.roadTeam, this.gameDay)
        .subscribe((streak) => {
          this.roadStreak = streak.win? "W"+streak.streak :"L"+streak.streak;
        });

      this.trelloService
        .getCardsByList(this.game.roadTeam)
        .subscribe((roadInfos) => {
          this.roadInfos = roadInfos;
        });
      this.trelloService
        .getCardsByList(this.game.homeTeam)
        .subscribe((homeInfos) => {
          this.homeInfos = homeInfos;
        });
    });

    this.statsService
      .getStandings(this.game.homeTeam)
      .subscribe((standings) => {
        this.homeStanding = standings;
      });

    this.statsService
      .getStandings(this.game.roadTeam)
      .subscribe((standings) => {
        this.roadStanding = standings;
      });

    this.statsService
      .getRanks(this.statsService.getCorrectName(this.game.homeTeam))
      .subscribe((homeRanks) => {
        this.homeRanks = homeRanks.homeRanks;
        // console.log("Home ranks update -- ", homeRanks);
      });

    this.statsService
      .getRanks(this.statsService.getCorrectName(this.game.roadTeam))
      .subscribe((roadRanks) => {
        // console.log("Road ranks update -- ", roadRanks);
        this.roadRanks = roadRanks.roadRanks;
      });

      const [roadScoringPosition, homeScoringPosition] = await lastValueFrom(
        forkJoin([
          this.statsService.getScoringPosition(
            this.statsService.getCorrectName(this.game.roadTeam)
          ),
          this.statsService.getScoringPosition(
            this.statsService.getCorrectName(this.game.homeTeam)
          ),
        ])
      );
  
  
      this.homeScoringPosition = homeScoringPosition
      this.roadScoringPosition = roadScoringPosition
  
  }

  getRestColor(rest: Rest): string {
    try {

      if (rest.isBackToBack) {
        return "red";
      } else if (rest.gamesLastFourDay === 3) {
        return "orange";
      } else if (rest.gamesLastFourDay === 2) {
        return "yellow";
      } else {
        return "green";
      }
    } catch {}
  }

  getStatMuseLink() {
    // console.log('redirect to stat muse');
    window.location.href = `https://www.statmuse.com/nba/ask/${this.game.roadName}-vs-${this.game.homeName}-this-season`;
  }
}
