// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none;
  color: rgb(247, 241, 241);
}
a:hover {
  color: rgb(255, 255, 255);
}
a:active {
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/header/logged-header/logged-header.component.scss"],"names":[],"mappings":"AAGA;EACC,qBAAA;EACA,yBALS;AAGV;AAGC;EACC,yBANQ;AAKV;AAGC;EACC,yBATQ;AAQV","sourcesContent":["$color_1: rgb(247, 241, 241);\r\n$color_2: rgb(255, 255, 255);\r\n\r\na {\r\n\ttext-decoration: none;\r\n\tcolor: $color_1;\r\n\t&:hover {\r\n\t\tcolor: $color_2;\r\n\t}\r\n\t&:active {\r\n\t\tcolor: $color_2;\r\n\t}\r\n}\r\n// .navigation-items {\r\n// \tlist-style-type: none;\r\n// \tpadding: 0;\r\n// \tmargin: 0;\r\n// }\r\n// mat-toolbar {\r\n//   border-radius: 3px;\r\n//   background-color: rgb(79, 79, 116);\r\n// }\r\n// @media (max-width: 959px) {\r\n// \tmat-toolbar {\r\n// \t\tborder-radius: 0px;\r\n// \t}\r\n// }\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
