import { Injectable, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { ApiResponse } from './signup-response';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { SigninRequest } from '../../models/signin-request.model';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthService implements OnInit {
  user = new BehaviorSubject<User>(this.getLocalUser());
  isAuth = new BehaviorSubject<boolean>(this.hasToken());
  apiUrl = 'http://localhost:9090/api/users/all';

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit() {}

  createNewUser(user: User) {
    console.log('Create new user');
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json'
    });
    return this.http
      .post<ApiResponse>(
        '/api/auth/signup',
        JSON.stringify(user),
        { headers }
      )
      .pipe(catchError(this.signupErrorHandler));
  }

  signInUser(email: string, password: string) {
    console.log('Login');

    const credential = new SigninRequest(email, password);

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json'
    });
    return this.http
      .post<ApiResponse>(
        '/api/auth/signin',
        JSON.stringify(credential),
        { headers }
      )
      .pipe(catchError(this.signupErrorHandler));
  }

  getCurrentUserFromApi(token: string) {
    const decodeToken = jwtDecode(token);
    return this.http
      .get<User>('/api/users/' + decodeToken.sub)
      .pipe(catchError(this.signupErrorHandler));
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  hasToken(): boolean {
    return !!localStorage.getItem('token');
  }

  saveToken(token: string) {
    localStorage.setItem('token', token);
    this.getCurrentUserFromApi(token).subscribe(userResponse => {
      const user = new User(
        userResponse.lastName,
        userResponse.firstName,
        userResponse.birthDate,
        userResponse.email,
        userResponse.titre
      );
      this.saveCurrentUserToLocal(user);
      this.user.next(this.getLocalUser());
    });
  }

  saveCurrentUserToLocal(userToLocal: User) {
    localStorage.setItem('currentUser', JSON.stringify(userToLocal));
    this.user.next(this.getLocalUser());
  }

  getLocalUser(): User {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user;
  }

  // todo getExpiredToken
  isAuthenticated(): Observable<boolean> {
    return this.isAuth.asObservable();
  }

  getCurrentAuthenticatedUser(): Observable<User> {
    return this.user.asObservable();
  }

  tokenNotExpired(token: string) {
    if (this.isAuth) {
      return true;
    } else {
      return false;
    }
  }

  signOutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    this.user.next(new User('', '', '00/00/00', '', ''));
    this.isAuth.next(false);
  }

  private signupErrorHandler(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 400) {
      console.error('Error: ', httpErrorResponse.error.message);
    } else {
      console.error('Backend error: ', httpErrorResponse.error);
    }
    window.alert(httpErrorResponse.error.message);
    return throwError('Une erreur s\'est produite');
  }
}
