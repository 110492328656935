import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AccueilComponent } from '../pages/accueil/accueil.component';
import { SignupComponent } from '../auth/signup/signup.component';
import { SigninComponent } from '../auth/signin/signin.component';
import { SignoutComponent } from '../auth/signout/signout.component';

const routes: Routes = [
  { path: 'auth/signup', component: SignupComponent },
  { path: 'auth/signin', component: SigninComponent },
  { path: 'auth/signout', component: SignoutComponent },
  { path: 'game/view/:id', component: AccueilComponent },
  { path: '', redirectTo: 'home', pathMatch: 'prefix' },
];

@NgModule({
    imports: [CommonModule, RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
