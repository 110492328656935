// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadowbox {
  height: 100%;
  width: 70%;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 15px 15px 15px rgb(79, 79, 116);
  padding: 15px 15px;
  background-color: rgb(79, 79, 116);
  border-radius: 2em;
}

input {
  width: 60%;
  padding: 8px 14px;
  margin: 2px;
  box-sizing: content-box;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

form {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/signup/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,8BAAA;EACA,2CAAA;EACA,kBAAA;EACA,kCAAA;EACA,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;EACA,WAAA;EACA,uBAAA;EAEA,cAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".shadowbox {\r\n  height: 100%;\r\n  width: 70%;\r\n  border: 1px solid rgb(0, 0, 0);\r\n  box-shadow: 15px 15px 15px rgb(79, 79, 116);\r\n  padding: 15px 15px;\r\n  background-color: rgb(79, 79, 116);\r\n  border-radius: 2em;\r\n}\r\n\r\ninput {\r\n  width: 60%;\r\n  padding: 8px 14px;\r\n  margin: 2px;\r\n  box-sizing: content-box;\r\n  // display: block;\r\n  margin : 0 auto;\r\n}\r\n\r\nh1 {\r\n  text-align: center;\r\n}\r\n\r\nform {\r\n  text-align: center;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
