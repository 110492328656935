import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth/auth-service";

@Component({
  selector: "app-logged-header",
  templateUrl: "./logged-header.component.html",
  styleUrls: ["./logged-header.component.scss"],
})
export class LoggedHeaderComponent implements OnInit {
  user: User;

  constructor(private authService: AuthService) {
    // console.log('Connected header');
    // console.log(this.authService.user.getValue());
    this.authService.getCurrentAuthenticatedUser().subscribe((value) => {
      this.user = value;
    });
  }

  ngOnInit() {}
}
