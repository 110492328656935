import { Component, Input, OnInit } from '@angular/core';
import { Game, RosterList } from 'src/app/models/games/games.models';
import { NBAPlayer } from 'src/app/models/games/nba-player.models';
import { StatsService } from 'src/app/services/stats/stats-service';

@Component({
  selector: 'app-main-roster',
  templateUrl: './main-roster.component.html',
  styleUrls: ['./main-roster.component.scss']
})
export class MainRosterComponent implements OnInit {
  @Input() gamesInput: Game;
  roadRoster : RosterList;
  homeRoster : RosterList;

  constructor(private statsService: StatsService,
    ) { }

  ngOnInit(): void {
    // this.statsService.getRosterPointsList(this.gamesInput.roadTeam).subscribe(
    //   (awayRoster) => {
    //     console.log("roadRoster", awayRoster)
    //     this.roadRoster = awayRoster;
    //   })

    //   this.statsService.getRosterPointsList(this.gamesInput.homeTeam).subscribe(
    //   (homeRoster) => {
    //     console.log("homeRoster", homeRoster)
    //     this.homeRoster = homeRoster;
    //   })
  }

}
