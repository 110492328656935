import { Role } from './role.model';
export class User {
  id: number;
  lastName: string;
  firstName: string;
  birthDate?: string;
  email: string;
  titre: string;
  password: string;
  roles: Role[];
  constructor(lastName: string, firstName: string, birthDate: string, email: string, titre: string, password?: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.titre = titre;
    this.password = password;
    this.birthDate = birthDate;
  }
}
