// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font {
  background-image: url("/assets/font5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  min-height: 93vh;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
}

.shadowbox {
  height: 100%;
  width: 50%;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 15px 15px 15px rgb(241, 144, 16);
  padding: 15px 15px;
  margin-top: 10%;
  background-color: rgb(0, 0, 0);
  border-radius: 2em;
  text-align: center;
  opacity: 60%;
}

input {
  width: 60%;
  padding: 8px 14px;
  box-sizing: content-box;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

form {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/signin/signin.component.scss"],"names":[],"mappings":"AAAA;EAEE,0CAAA;EAMA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,eAAA;EAEA,gBAAA;EACA,8BAAA;EACA,8BAAA;AANF;;AAYA;EACE,YAAA;EACA,UAAA;EACA,8BAAA;EACA,4CAAA;EACA,kBAAA;EACA,eAAA;EAEA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAVF;;AAgBA;EACE,UAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAbF;;AAgBA;EACE,kBAAA;AAbF;;AAgBA;EACE,kBAAA;AAbF","sourcesContent":[".font {\r\n  // display: flex;\r\n  background-image: url(\"/assets/font5.jpg\");\r\n  // height: auto;\r\n  // -webkit-background-size: cover;\r\n  // -moz-background-size: cover;\r\n  // -o-background-size: cover;\r\n  // background-size: cover;\r\n  background-position: center;\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  min-width: 100%;\r\n  // min-height: calc(100vh - 6vh);\r\n  min-height: calc(100vh - 7vh);\r\n  border: 1px solid rgb(0, 0, 0);\r\n  background-color: rgb(0, 0, 0);\r\n  // margin: px;\r\n  // display: flex;\r\n\r\n}\r\n\r\n.shadowbox {\r\n  height: 100%;\r\n  width: 50%;\r\n  border: 1px solid rgb(0, 0, 0);\r\n  box-shadow: 15px 15px 15px rgb(241, 144, 16);\r\n  padding: 15px 15px;\r\n  margin-top: 10%;\r\n  // margin-left: 15%;\r\n  background-color: rgb(0, 0, 0);\r\n  border-radius: 2em;\r\n  text-align: center;\r\n  opacity: 60%;\r\n  // justify-self: center;\r\n  // align-items: center;\r\n  // vertical-align: ;\r\n}\r\n\r\ninput {\r\n  width: 60%;\r\n  padding: 8px 14px;\r\n  box-sizing: content-box;\r\n  margin : 0 auto;\r\n}\r\n\r\nh1 {\r\n  text-align: center;\r\n}\r\n\r\nform {\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
