// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 90%;
}

.rosters {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.rosterHome, .rosterRoad {
  margin-top: 50px;
  width: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/roster/roster.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAQA;EACE,aAAA;EACA,8BAAA;AALF;;AAQA;EACE,gBAAA;EAIA,cAAA;AARF","sourcesContent":["table {\r\n  width: 90%;\r\n}\r\n\r\n// mat-checkbox {\r\n//   color: black;\r\n//   background-color: black;\r\n//   border-color: black;\r\n// }\r\n\r\n.rosters {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n}\r\n\r\n.rosterHome, .rosterRoad {\r\n  margin-top: 50px;\r\n  // padding-left:200px;\r\n  // background-color: red;\r\n  // height: 300px;\r\n  width: inherit;\r\n  // color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
