import { Component, Input } from "@angular/core";
import { LastFive } from "src/app/models/games/games.models";
import { StatsService } from "src/app/services/stats/stats-service";

@Component({
  selector: "app-last-games",
  standalone: false,
  // imports: [],
  templateUrl: "./last-games.component.html",
  styleUrl: "./last-games.component.scss",
})
export class LastGamesComponent {
  @Input() team: string;
  @Input() date: string;
  @Input() location: string;
  lastGames: LastFive;
  lastGamesRoad: LastFive;
  lastGamesHome: LastFive;

  constructor(private statsService: StatsService) {}

  async ngOnInit(): Promise<void> {
    if (this.location == "road") {
      this.statsService
        .getLastFiveHomeRoad(this.team, this.date, this.location)
        .subscribe(
          (lastFiveRoad) => {
            this.lastGames = lastFiveRoad;
          },
          (error) => {
            console.error("Error fetching last five games ROAD:", error);
          }
        );
    } else if (this.location == "home") {
      this.statsService
        .getLastFiveHomeRoad(this.team, this.date, this.location)
        .subscribe(
          (lastFiveRoad) => {
            this.lastGames = lastFiveRoad;
          },
          (error) => {
            console.error("Error fetching last five games HOME:", error);
          }
        );
    } else {
      this.statsService.getLastFive(this.team, this.date).subscribe(
        (lastFiveListRoad) => {
          this.lastGames = lastFiveListRoad;
        },
        (error) => {
          console.error("Error fetching last five games Total:", error);
        }
      );
    }
  }
}
