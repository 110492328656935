import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralComponent implements OnInit {
  @Input() title: string;

  @Input() firstStat: string;
  @Input() roadFirstStat = '';
  @Input() roadFirstStatOpp = '';
  @Input() homeFirstStat = '';
  @Input() homeFirstStatOpp = '';

  @Input() secondStat: string;
  @Input() roadSecondStat = '';
  @Input() roadSecondStatOpp = '';
  @Input() homeSecondStat = '';
  @Input() homeSecondStatOpp = '';

  @Input() thirdStat: string;
  @Input() roadThirdStat = '';
  @Input() roadThirdStatOpp = '';
  @Input() homeThirdStat = '';
  @Input() homeThirdStatOpp = '';

  @Input() fouthStat: string;
  @Input() roadFourthStat = '';
  @Input() roadFourthStatOpp = '';
  @Input() homeFourthStat = '';
  @Input() homeFourthStatOpp = '';
  constructor() {}

  ngOnInit(): void {}
}
