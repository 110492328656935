// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  tr {
    font-size: 8px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/last-games/last-games.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EAEI,uBAAA;EACA;IACI,yBAAA;EAAN;AACF","sourcesContent":["td {\r\n    white-space: nowrap;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n\r\n    /* For mobile phones: */\r\n    tr {\r\n        font-size: 8px !important;\r\n    }\r\n    \r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
