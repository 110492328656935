import { EventEmitter, Injectable, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import {
  Game,
  TeamStats,
  StandingsOldModel,
  Ranks,
  RosterList,
  RosterPointsList,
  Standings,
  LastFive,
  Rest,
  ScoringPosition,
  Streak,
} from 'src/app/models/games/games.models';
import { AverageStat } from 'src/app/models/games/team-stats.model';
import { NBAPlayer } from 'src/app/models/games/nba-player.models';

@Injectable()
export class StatsService implements OnInit {
  game = new BehaviorSubject<Game[]>(this.getLocalGameList());
  roadStatsTrigger = new EventEmitter()
  homeStatsTrigger = new EventEmitter()


  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) {}

  getScheduleByDate(date: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      gameDay: date,
    });
    return this.http
      .get<Game[]>('/api/schedule/date', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getTeamStats(team: string, date: string ) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
      gameDay: date
    });
    return this.http
      .get<TeamStats>(`/api/stats/team`, { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }
  
  getScoringPosition(team: string ) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    
    return this.http
      .get<ScoringPosition>(`/api/stats/scoring/position`, { headers })
      .pipe(catchError(this.scoringPositionErrorHandler));
  }

  getRanks(team: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    return this.http
      .get<Ranks>('/api/stats/ranks', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getLastFive(team: string, date: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
      gameDay: date
    });
    return this.http
      .get<LastFive>('/api/schedule/date/last', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }
  
  getLastFiveHomeRoad(team: string, date: string, location: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
      gameDay: date,
      location
    });
    return this.http
      .get<LastFive>('/api/schedule/date/lastHomeRoad', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getStandingsOld(team: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    return this.http
      .get<StandingsOldModel[]>('/api/stats/standings', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getStandings(team: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    return this.http
      .get<Standings>('/api/stats/standings', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }
  
  getRest(team: string, date: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
      gameDay: date
    });
    return this.http
      .get<Rest>('/api/schedule/date/rest', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }
  
  getStreak(team: string, date: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
      gameDay: date
    });
    return this.http
      .get<Streak>('/api/schedule/date/streak', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getRosterHomeList(team: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    return this.http
      .get<RosterList>('/api/stats/roster/home', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }
  
  getRosterRoadList(team: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      team,
    });
    return this.http
      .get<RosterList>('/api/stats/roster/road', { headers })
      .pipe(catchError(this.scheduledErrorHandler));
  }

  getGameList(date: string) {
    const gameList = this.getScheduleByDate(date);
    return gameList;
  }

  getLocalGameList(): Game[] {
    return JSON.parse(localStorage.getItem('gameList'));
  }


  getCurrentGameList(): Observable<Game[]> {
    return this.game.asObservable();
  }

  getCorrectName(teamName: string) {
    if (teamName === "Los Angeles Clippers") {
      return "LA Clippers";
    } else if (teamName === "Philadelphia 76Ers") {
      return "Philadelphia 76ers";
    } else {
      return teamName;
    }
  }

  private scheduledErrorHandler(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 400) {
      console.error('Error: ', httpErrorResponse.error.message);
    } else {
      console.error('Backend error: ', httpErrorResponse.error);
    }
    // window.alert('Erreur lors de la récuperation des match');
    return throwError('Une erreur s\'est produite');
  }
  
  private scoringPositionErrorHandler(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 400) {
      console.error('Error: ', httpErrorResponse.error.message);
    } else {
      console.error('Backend error: ', httpErrorResponse.error);
    }
    // window.alert('Erreur lors de la récuperation des match');
    return throwError('Une erreur s\'est produite');
  }


}
