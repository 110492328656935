// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-tab-group {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  mat-tab-group {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/main-roster/main-roster.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0BAAA;EACA,WAAA;AACF;;AAGA;EACE,uBAAA;EACA;IAGE,WAAA;EAFF;AACF","sourcesContent":["mat-tab-group {\r\n  display: grid;\r\n  grid-template-columns: 1fr;\r\n  width: 100%;\r\n  // min-height: 800px;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  /* For mobile phones: */\r\n  mat-tab-group {\r\n    // display: grid;\r\n    // grid-template-columns: 1fr;\r\n    width: 100%;\r\n    // min-height: 800px;\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
