import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TrelloService {
  constructor(private http: HttpClient) {}

  getCardsByList(team: string) {
    // console.log('Infos update');
    const headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
      team,
    });
    return this.http
      .get<string[]>("/api/trello/lists/cards", { headers })
      .pipe(catchError(this.trelloErrorHandler));
  }

  private trelloErrorHandler(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 400) {
      console.error("Error: ", httpErrorResponse.error.message);
    } else {
      console.error("Backend error: ", httpErrorResponse.error);
    }
    window.alert(httpErrorResponse.error.message);
    return throwError("Une erreur s'est produite");
  }
}
