import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import {
  Game, TeamRoster
} from "../../models/games/games.models";
import { StatsService } from "../../services/stats/stats-service";
import { MatSort } from "@angular/material/sort";
import {
  MatCheckboxChange,
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from "@angular/material/checkbox";

@Component({
  selector: "app-roster-points",
  templateUrl: "./roster-points.component.html",
  styleUrls: ["./roster-points.component.scss"],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: "check" } as MatCheckboxDefaultOptions,
    },
  ],
})
export class RosterPointsComponent implements OnInit, AfterViewInit {
  @Input() game: Game;
  @ViewChild(MatSort, { static: false }) sortHome: MatSort;
  @ViewChild(MatSort, { static: false }) sortRoad: MatSort;

  displayedColumns: string[] = [
    "select",
    "player",
    // "position",
    "gamePlayed",
    "minutesPlayed",
    "fieldGoalsMade",
    "threePointsMade",
    "freeThrowsMade",
    "rebounds",
    "assists",
    "points",
    "plusMinus",
  ];
  dataSourceHome = new MatTableDataSource<TeamRoster>();
  dataSourceRoad = new MatTableDataSource<TeamRoster>();
  selectionHome = new SelectionModel<TeamRoster>(true, [], true);
  selectionRoad = new SelectionModel<TeamRoster>(true, [], true);
  roadConterCheckbox = 0;
  homeConterCheckbox = 0;
  affectedPercentage = 0.35

  constructor(private statsService: StatsService) {}

  ngOnInit(): void {
    this.statsService
      .getRosterHomeList(this.statsService.getCorrectName(this.game.homeTeam))
      .subscribe((rosterList) => {
        // console.log("RosterHome - Points update", rosterList);
        this.dataSourceHome.data = rosterList.rosterList;
        this.dataSourceHome.data.forEach((row: TeamRoster) => {
          if (localStorage.getItem(row.player)) {
            this.selectionHome.select(row);
            this.updateStatsHome(row);
          }
        });
      });

    this.statsService
      .getRosterRoadList(this.statsService.getCorrectName(this.game.roadTeam))
      .subscribe((rosterList) => {
        // console.log("RosterRoad - Points update", rosterList);
        this.dataSourceRoad.data = rosterList.rosterList;
        this.dataSourceRoad.data.forEach((row: TeamRoster) => {
          if (localStorage.getItem(row.player)) {
            this.selectionRoad.select(row);
            this.updateStatsRoad(row);
          }
        });
      });
  }

  updateStatsRoad(row: TeamRoster, minus = true) {
    let pointsUpdate = 0;
    setTimeout(() => {
      if (minus) {
        if (this.selectionRoad.select.length > 1) {
          this.selectionRoad.selected.forEach((currentRow) => {
            pointsUpdate = pointsUpdate - currentRow.points;
          });
        
        } else {
          pointsUpdate = pointsUpdate - row.points;
        }
      } else {
        if (this.selectionRoad.select.length > 1) {
          this.selectionRoad.selected.forEach((currentRow) => {
            pointsUpdate = pointsUpdate + currentRow.points;
          });
          
        } else {
          pointsUpdate = (pointsUpdate + row.points);
        }
      }
      pointsUpdate = Math.round(pointsUpdate * this.affectedPercentage);
      this.statsService.roadStatsTrigger.emit(pointsUpdate);
    }, 100);
  }

  private updateStatsHome(row: TeamRoster, minus = true) {
    let pointsUpdate = 0;
    setTimeout(() => {
      if (minus) {
        if (this.selectionHome.select.length > 1) {
          this.selectionHome.selected.forEach((currentRow) => {
            pointsUpdate = pointsUpdate - currentRow.points;
          });
        
        } else {
          pointsUpdate = pointsUpdate - row.points;
        }
      } else {
        if (this.selectionHome.select.length > 1) {
          this.selectionHome.selected.forEach((currentRow) => {
            pointsUpdate = pointsUpdate + currentRow.points;
          });
          
        } else {
          pointsUpdate = (pointsUpdate + row.points);
        }
      }
      pointsUpdate = Math.round(pointsUpdate * this.affectedPercentage);
      this.statsService.homeStatsTrigger.emit(pointsUpdate);
    }, 100);
  }

  ngAfterViewInit() {
    this.dataSourceHome.sort = this.sortHome;
    this.dataSourceRoad.sort = this.sortRoad;
  }

  getActiveSort() {
    return "minutesPlayed";
  }

  getSortDirection() {
    return "desc";
  }
  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      values.push({ key: keys[i], value: localStorage.getItem(keys[i]) });
    }

    return values;
  }

  newToggleRow(row: TeamRoster, event: MatCheckboxChange, road = false) {
    if (event.source.checked) {
      localStorage.setItem(row.player, "true");
      road ? (this.roadConterCheckbox += 1) : (this.homeConterCheckbox += 1);
      // console.log(localStorage.getItem(row.id.toString()));
      road ? this.updateStatsRoad(row) : this.updateStatsHome(row);
    } else {
      road ? (this.roadConterCheckbox -= 1) : (this.homeConterCheckbox -= 1);
      road ? this.updateStatsRoad(row, false) : this.updateStatsHome(row, false);
      localStorage.removeItem(row.player.toString());
    }
  }
}
