import { Component, OnInit, Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { AuthService } from '../../services/auth/auth-service';
import { Router } from '@angular/router';
import { ApiResponse } from '../../services/auth/signup-response';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
@Injectable()
export class SigninComponent implements OnInit {
  signinForm: UntypedFormGroup;
  errorMessage: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    console.log('Login Page');
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const controlEmail = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]);
    const controlPassword = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/[0-9a-zA-Z]{6,}/)
    ]);

    this.signinForm = this.formBuilder.group({
      email: ['', controlEmail],
      password: ['', controlPassword]
    });
  }

  onSubmit() {
    const email = this.signinForm.get('email').value;
    const password = this.signinForm.get('password').value;

    this.authService.signInUser(email, password).subscribe(
      (signinResponse: ApiResponse) => {
        if (signinResponse.success) {
          const token = signinResponse.message;
          this.authService.saveToken(token);
          this.authService.user.next(this.authService.user.getValue());
          this.authService.isAuth.next(true);
          this.router.navigate(['/association']);
        }
      },
      error => (signinError: ApiResponse) => {
        this.errorMessage = signinError.message;
        this.authService.isAuth.next(signinError.success);
      },
      () => {}
    );
  }
}
