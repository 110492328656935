import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth-service";

@Component({
  selector: "app-unlogged-header",
  templateUrl: "./unlogged-header.component.html",
  styleUrls: ["./unlogged-header.component.scss"],
})
export class UnloggedHeaderComponent implements OnInit {
  @Output() menuButton: EventEmitter<any> = new EventEmitter();

  constructor(private authService: AuthService) {
    // console.log('Unconnected header');
    this.authService.signOutUser();
    // localStorage.clear();
  }

  ngOnInit() {}
}
